<template>
  <section id="modal" v-show="visible">
    <div class="modal-prompt">
      <img src="@/assets/modal_close.png" alt @click="handleCloseClick">
      <img src="@/assets/modal_title.png" alt>
      <h1>客服</h1>
      <p>QQ：3007385675</p>
      <p>受理时间：周一至周五</p>
      <p>9:30-20:00</p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  methods:{
    handleCloseClick(){
      this.$emit('update:visible',false)
    }
  }
};
</script>

<style>
#modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 1.34rem;
  z-index: 120;
  width: 100%;
  background-color: rgba(25, 25, 25, 0.9);
}

#modal * {
  margin: 0 auto;
  text-align: center;
}

#modal p {
  font-size: 0.25rem;
}

#modal .modal-prompt {
  max-width: 10rem;
  position: absolute;
  top: 2.56rem;
  left: 0;
  right: 0;
}

#modal .modal-prompt {
  width: 4.32rem;
  padding: 0.4rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#modal .modal-prompt img:nth-child(1) {
  width: 0.45rem;
  height: 0.45rem;
  position: absolute;
  right: -0.28rem;
  top: -1.2rem;
}

#modal .modal-prompt img:nth-child(2) {
  width: 5.12rem;
  height: 0.77rem;
  position: absolute;
  top: -0.6rem;
}

#modal .modal-prompt h1 {
  position: absolute;
  top: -0.46rem;
  color: white;
  font-size: 0.3rem;
}
</style>
