<template>
  <div>
    <mly-header></mly-header>

    <section id="slider">
      <img
        src="https://tjy-1253841540.cosgz.myqcloud.com/pic/official_website_wap/project_files/images/banner_dmx.jpg"
        alt
        class="img-responsive"
      >
    </section>
    <section id="user-info">
      <div class="content">
        <router-link to="./login">
          <img
            src="@/assets/logo_head_sculpture.png"
            alt
            class="head-sculpture img-circle img-responsive"
          >
        </router-link>
        <div class="text">
          <p style="opacity: 0;">这个人很懒，什么也没有留下。</p>
          <div class="text-top">
            <span style="color: #20A2DC">{{GlobalObject.User ? GlobalObject.User.UPDATE_LOGIN_ACCOUNT : ''}}</span>
            <!-- <img src="images/icon_vip.png" class="img-responsive" alt=""> -->
          </div>
        </div>
      </div>
    </section>

    <list></list>
    <mly-placeholder></mly-placeholder>
    <mly-toolbar></mly-toolbar>
  </div>
</template>
<script>
import list from "./list";

export default {
  components: {
    list
  },
  data(){
    return {
      GlobalObject
    }
  }
};
</script>
<style>
#slider {
  border-bottom: none;
  min-height: 3.6rem;
}

#slider img {
  width: 100%;
  height: 100%;
}

#user-info {
  height: 0.99rem;
  border-bottom: 0.02rem solid #f4f4f4;
  padding: 0 0.24rem;
}

#user-info .content {
  display: flex;
  position: relative;
  top: -0.74rem;
}

#user-info .head-sculpture {
  width: 1.48rem;
  height: 1.48rem;
}

#user-info .text {
  margin-left: 0.2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#user-info .text-top {
  display: flex;
  align-items: center;
  color: white;
}

#user-info .text-top img {
  width: 0.95rem;
  height: 0.32rem;
  margin-left: 0.2rem;
}

#user-info span {
  font-size: 0.26rem;
}

#user-info p {
  font-size: 0.22rem;
}
</style>
