<template>
  <section id="function-list">
    <div class="item unused">
      <img src="@/assets/icon_imperial_crown.png" alt>
      <span>我的钱包</span>
      <img src="@/assets/icon_right_arrow.png" alt class="pull-right">
    </div>
    <div class="item unused">
      <img src="@/assets/icon_shop.png" alt>
      <span>VIP会员</span>
      <img src="@/assets/icon_right_arrow.png" alt class="pull-right">
    </div>
    <div class="item unused">
      <img src="@/assets/icon_gifts_2.png" alt>
      <span>我的礼包</span>
      <img src="@/assets/icon_right_arrow.png" alt class="pull-right">
    </div>
    <div class="item" @click="lianxikefu">
      <img src="@/assets/icon_custom_service.png" alt>
      <span>联系客服</span>
      <img src="@/assets/icon_right_arrow.png" alt class="pull-right">
    </div>
    <div class="item unused">
      <img src="@/assets/icon_safe_center.png" alt>
      <span>安全中心</span>
      <img src="@/assets/icon_right_arrow.png" alt class="pull-right">
    </div>
    <div class="item" @click="exit">
      <img src="@/assets/icon_exit.png" alt>
      <span>退出</span>
      <img src="@/assets/icon_right_arrow.png" alt class="pull-right">
    </div>

  </section>
</template>

<script>
import util from '../../commons/util.js'
import Const from '../../commons/const.js'

import modal from "./modal";

export default {
  components: {
    modal
  },
  data() {
    return {

    };
  },
  methods: {
    lianxikefu(){
      util.showPromptModal('QQ：3007385675<br/>受理时间：周一至周五<br/>9:30-20:00');
    },
    exit() {
      if (GlobalObject.User) {
        util.delCookie(Const.UserCookieKey);
        location.reload();
      }
    }
  }
};
</script>

<style>
#function-list .item {
  padding: 0.26rem 0.2rem;
  border-bottom: 0.02rem solid #f4f4f4;
  cursor: pointer;
}

#function-list .item span {
  vertical-align: middle;
  margin-left: 0.2rem;
  font-size: 0.31rem;
}

#function-list .unused {
  color: #b6b6b6;
}

#function-list .item:nth-child(2),
#function-list .item:nth-child(5) {
  border-bottom: 0.22rem #f4f4f4 solid;
}

#function-list img {
  width: 0.6rem;
  height: 0.6rem;
}
</style>
